header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}


/* Set container to center horizontally */

.container.header__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/* CSS for profile photo */

.profilePhoto {
    width: 22rem;
    height: 30rem;
    border-radius: 50%;
    margin-right: 10px;
    position: absolute;
    left: calc(50% - 11rem);
    overflow: hidden;
    padding-bottom: 4rem;
}


/* CSS for CTA */

.cta {
    margin-top: 2.5rem;
    flex-direction: flex;
    gap: 1.2rem;
    justify-content: center;
    margin-bottom: 2.5rem;
}

.btnDownload {
    margin-right: 2rem;
}


/* CSS for header Socials */

.header__socials {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}


/* Media Queries (Medium Devices) */

@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
}


/* Media Queries for Mobile */

@media screen and (max-width: 768px) {
    header {
        height: 80vh;
    }
    .header__socials,
    .scroll__down {
        display: none;
    }

    .profilePhoto {
        width: 18rem;
        height: 26rem;   
        margin-right: 10px;
        padding-bottom: 4rem;
        left: calc(50% - 9rem);
    }
}

@media screen and (max-width: 351px) {
    .cta {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    /* center button */
    .btnContactMe {
        margin: 0 auto;
    }
    .btnDownload {
        margin: 0 auto;
    }

}

@media screen and (max-width: 376px) {
 header {
    height: 100vh;
 }

}