footer {
    background-image: linear-gradient(to bottom, #24E360, #1da046);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}

footer a {
    color: var(--color-bg);
}

.footer__logo {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
}

.logo {
    height: 100px;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    cursor: pointer;
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}

.footer__socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
}

.footer__socials a {
    background: var(--color-bg);
    color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
}

.footer__socials a:hover {
    background: transparent;
    color: var(--color-bg);
    border-color: var(--color-bg);
}

.footer__copyright {
    margin-bottom: 4rem;
    color: var(--color-bg);
}


/* Media Queries for Mobile Devices */

@media screen and (max-width: 600px) {
    /*center permalinks */
    .permalinks {
        gap: 1rem;
        margin-right: 20px;
    }
    .footer__socials {
        margin-bottom: 2.6rem;
    }
}