/* Set Spinner at center */

.spinner {
    margin-top: 10px;
    margin-left: 50%;
}

.portfolio__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}


/* Set size for image  */

.portfolio__item-image {
    border-radius: 1.5rem;
   overflow: hidden;
    padding-bottom: 20px;
}

.portfolio__item-title {
    text-align: center;
    padding-bottom: 10px;
}

.portfolio__item h3 {
    margin: 1.2 0 2rem;
}

.portfolio__item-cta {
    display: flex;
    flex-direction: row;
}

.title_designProject {
    margin-top: 3rem;
}
.portfolio__item-description {
    text-align: center;
    margin-bottom: 15px;
}


/* Css for Portfolio buttons */

.portfolio__item-cta .btn {
    margin: 0 auto;
}


/* Media Queries for Tablet devices */

@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.2rem;
    }
}


/* Media Queries for Mobile devices */

@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}

@media screen and (max-width: 375px) {
    .btnGitHub {
        width: 50px;
    }
    .btnLive {
        width: 50px;
    }
}